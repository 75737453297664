import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import pMinDelay from "p-min-delay";
import { replace } from "connected-react-router";
import { getCookie } from "acm-components/module/lib/cookie";

import { AppState } from "@store";
import settings from "@settings";
import { APIResponse, getErrorCode, getErrorDisplayCode, isErrorResponse } from "@lib/api";
import { isAccountEntitled, isAccountWithSmartcardData, isAvailable, isActive, isInProgress } from "@ducks/apps/user";
import { ActivationSelection, SelectedSmartcard } from "./user/types";
import {
  ActivateRequest,
  ActivateResponse,
  DisneyActionErrorResponse,
  DisneyCheckEligibilityResponse,
  ListEntitlementsResponse,
  PurchaseOtpRequest,
  PurchaseOtpResponse,
  PurchaseRequest,
  PurchaseResponse,
  SmartcardEligibility,
  ValidateActivateRequest,
  ValidateActivateResponse,
  ValidateEligibilityResponse,
  ValidatePurchaseErrorResponse,
  ValidatePurchaseRequest,
  ValidatePurchaseResponse,
} from "./types";
import { bcpContactForm, errorCodes, reason, waitingRoom, validationErrorTokenValue } from "./constants";
import { trackDisneyError, trackDisneyTraffic } from "./analyticActions";
import { disneyContactPath } from "@constants/paths";
import { AppsContactState } from "./contact/types";
import { isTokenValidationError } from "./leadActions";
import { cookie } from "./user/constants";

export const checkEligibility = createAsyncThunk<
  ValidateEligibilityResponse,
  boolean | undefined,
  { state: AppState; rejectValue: DisneyCheckEligibilityResponse }
>("appsDisney/checkEligibility", async (isInit, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let apiEndpoint = settings.apiEndpoint.listEntitlements;
  let httpStatus = "";
  let accountIds = "";
  try {
    // List Entitlements
    const entitlementsResponse = await delayedFetch(settings.apiEndpoint.listEntitlements, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ulmToken,
      },
    });
    httpStatus = entitlementsResponse.status.toString();
    const entitlementsData = (await entitlementsResponse.json()) as APIResponse<ListEntitlementsResponse>;
    if (isErrorResponse(entitlementsData.response)) {
      const errorCode = getErrorCode(entitlementsData.response);
      const errorDisplayCode = getErrorDisplayCode(entitlementsData.response);
      const error = { errorCode, errorDisplayCode, contactForm: false, trackTraffic: false };
      if (isContactPageError(appsContact, errorCode)) {
        error.contactForm = true;
        error.trackTraffic = true;
      }
      throw error;
    }
    const { entitlementToken, customerInfo } = entitlementsData.response;
    const entitlementAccounts = customerInfo.filter(isAccountEntitled);
    accountIds = customerInfo.map((account) => account.accountId).join(",");
    if (entitlementAccounts.length < 1) {
      const error = {
        errorCode: errorCodes.notEntitled,
        trackError: false,
        trackTraffic: true,
        reason: reason.statusNotActive,
      };
      throw error;
    }
    const entitlementAccountsWithSmartcards = entitlementAccounts.filter(isAccountWithSmartcardData);
    if (entitlementAccountsWithSmartcards.length < 1) {
      const error = {
        contactForm: true,
        trackError: false,
        trackTraffic: true,
        reason: reason.emptySmartcard,
        entitlementToken,
      };
      throw error;
    }
    // Validate Eligibility
    httpStatus = ""; // reset prior to next API call, in the event the API fails
    apiEndpoint = settings.apiEndpoint.validateEligibility;
    const eligibilityResponse = await delayedFetch(settings.apiEndpoint.validateEligibility, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ulmToken,
      },
      body: JSON.stringify({
        entitlementToken,
      }),
    });
    httpStatus = eligibilityResponse.status.toString();
    const eligibilityData = (await eligibilityResponse.json()) as APIResponse<ValidateEligibilityResponse>;
    if (isErrorResponse(eligibilityData.response)) {
      const errorCode = getErrorCode(eligibilityData.response);
      const errorDisplayCode = getErrorDisplayCode(eligibilityData.response);
      const error = { errorCode, errorDisplayCode, contactForm: false, trackTraffic: false };
      if (isContactPageError(appsContact, errorCode)) {
        error.contactForm = true;
        error.trackTraffic = true;
      }
      throw error;
    }
    const eligibilityAccounts = eligibilityData.response.customerInfo.filter(isAccountEntitled);
    if (eligibilityAccounts.length < 1) {
      const error = {
        errorCode: errorCodes.notEntitled,
        trackError: false,
        trackTraffic: true,
        reason: reason.statusNotActive,
      };
      throw error;
    }
    const eligibilityAccountsWithSmartcards = eligibilityAccounts.filter(isAccountWithSmartcardData);
    if (eligibilityAccountsWithSmartcards.length < 1) {
      const error = {
        contactForm: true,
        trackError: false,
        trackTraffic: true,
        reason: reason.emptySmartcard,
        entitlementToken,
      };
      throw error;
    }
    const accountsWithValidSmartcards = eligibilityAccountsWithSmartcards.filter(({ smartcards }) =>
      Object.values(smartcards).some(isSmartcardWithValidStatus)
    );
    if (accountsWithValidSmartcards.length < 1) {
      const error = {
        errorCode: errorCodes.notEntitled,
        trackError: false,
        trackTraffic: true,
        reason: reason.activationNotValid,
      };
      throw error;
    }
    return eligibilityData.response;
  } catch ({
    errorCode: _errorCode,
    errorDisplayCode: _errorDisplayCode,
    trackError = true,
    contactForm,
    trackTraffic,
    reason: _reason,
    entitlementToken,
  }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    const reason: string = _reason ?? errorCode;

    if (trackError) {
      thunkApi.dispatch(
        trackDisneyError({
          api_endpoint: apiEndpoint,
          error_code: errorCode,
          error_status: httpStatus,
          account_id: accountIds,
        })
      );
    }
    if (trackTraffic) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountIds,
          screen_name: contactForm ? (isInit ? waitingRoom : bcpContactForm) : errorCodes.notEntitled,
          reason,
          api_endpoint: apiEndpoint,
        })
      );
    }
    return thunkApi.rejectWithValue({
      errorCode,
      errorDisplayCode,
      contactForm,
      entitlementToken,
      accountId: accountIds,
      apiEndpoint,
    });
  }
});

export const validatePurchase = createAsyncThunk<
  ValidatePurchaseResponse,
  void,
  { state: AppState; rejectValue: ValidatePurchaseErrorResponse }
>("appsDisney/validatePurchase", async (request, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsUser: { eligibilityToken, purchaseSelection, disneyAddOnsConfig },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let httpStatus = "";
  const apiEndpoint = settings.apiEndpoint.validatePurchase;
  const phoneNumber = getMobileNumber(purchaseSelection);
  const { accountId, smartcardNumber: smc } = purchaseSelection.smartcard as SelectedSmartcard;
  try {
    const addOnId = purchaseSelection.addOnId;
    const purchaseCode = disneyAddOnsConfig.addOns.find(({ id }) => id === addOnId)?.purchaseCode ?? "";
    const requestBody: ValidatePurchaseRequest = {
      eligibilityToken,
      phoneNumber,
      accountId,
      smc,
      purchaseCode,
    };
    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ulmToken,
      },
      body: JSON.stringify(requestBody),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<ValidatePurchaseResponse>;
    if (isErrorResponse(data.response)) {
      const errorCode = getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error: {
        errorCode: string | null;
        errorDisplayCode: string | null;
        contactForm: boolean;
        eligibilityToken?: string;
      } = {
        errorCode,
        errorDisplayCode,
        contactForm: false,
      };
      if (errorCode === errorCodes.duplicatePurchaseValidation)
        error.eligibilityToken = data.response.errors[0]?.errorAttributes?.eligibilityToken as string;
      if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
      throw error;
    }
    return data.response;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, eligibilityToken, contactForm }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus,
        account_id: accountId,
      })
    );
    if (contactForm) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountId,
          screen_name: bcpContactForm,
          reason: errorCode,
          api_endpoint: apiEndpoint,
        })
      );
      thunkApi.dispatch(replace(`/${disneyContactPath}`));
    }
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, eligibilityToken, contactForm, apiEndpoint });
  }
});

export const validateActivation = createAsyncThunk<
  string,
  void,
  { state: AppState; rejectValue: DisneyActionErrorResponse }
>("appsDisney/validateActivation", async (request, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsUser: {
      eligibilityToken,
      activationSelection: { mobilePrefix, mobileSuffix, smartcard },
    },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let httpStatus = "";
  const apiEndpoint = settings.apiEndpoint.validateActivate;
  const { accountId, smartcardNumber } = smartcard as SelectedSmartcard;
  const phoneNumber = mobilePrefix + mobileSuffix;
  try {
    const body: ValidateActivateRequest = {
      eligibilityToken,
      accountId,
      smc: smartcardNumber,
      phoneNumber,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" && (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<ValidateActivateResponse>;
    if (isErrorResponse(data.response)) {
      const errorCode = getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error = { errorCode, errorDisplayCode, contactForm: false };
      if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
      throw error;
    }
    return data.response.activationValidateToken;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus,
        account_id: accountId,
      })
    );
    if (contactForm) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountId,
          screen_name: bcpContactForm,
          reason: errorCode,
          api_endpoint: apiEndpoint,
        })
      );
      thunkApi.dispatch(replace(`/${disneyContactPath}`));
    }
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
  }
});

export const activate = createAsyncThunk<string, string, { state: AppState; rejectValue: DisneyActionErrorResponse }>(
  "appsDisney/activate",
  async (otp, thunkApi) => {
    const {
      ulm: { ulmToken },
      appsOtp: { activationValidateToken },
      appsUser: {
        activationSelection: { smartcard, hasConsentedToUpdatePhone },
      },
      appsContact,
      appsLanguage: { fallbackErrorDisplayCode },
    } = thunkApi.getState();
    let httpStatus = "";
    const apiEndpoint = settings.apiEndpoint.activate;
    const { packId, accountId } = smartcard as SelectedSmartcard;
    try {
      const body: ActivateRequest = {
        activationValidateToken,
        otp,
        packId,
        isAlacarte: false,
        updatePrimaryPhone: hasConsentedToUpdatePhone,
      };
      process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" &&
        otp === process.env.REACT_APP_DISNEY_MOCK_OTP &&
        (body.mock = true);

      const response = await delayedFetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: ulmToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      httpStatus = response.status.toString();
      const data = (await response.json()) as APIResponse<ActivateResponse>;
      if (isErrorResponse(data.response)) {
        const isTokenError = isTokenValidationError(data.response, validationErrorTokenValue.activate);
        const errorCode = isTokenError ? errorCodes.validationErrorToken : getErrorCode(data.response);
        const errorDisplayCode = getErrorDisplayCode(data.response);
        const error = { errorCode, errorDisplayCode, contactForm: false };
        if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
        throw error;
      }
      return data.response.status;
    } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
      const errorCode: string = _errorCode ?? errorCodes.genericError;
      const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
      thunkApi.dispatch(
        trackDisneyError({
          api_endpoint: apiEndpoint,
          error_code: errorCode,
          error_status: httpStatus,
          account_id: accountId,
        })
      );
      if (contactForm) {
        thunkApi.dispatch(
          trackDisneyTraffic({
            account_id: accountId,
            screen_name: bcpContactForm,
            reason: errorCode,
            api_endpoint: apiEndpoint,
          })
        );
        thunkApi.dispatch(replace(`/${disneyContactPath}`));
      }
      return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
    }
  }
);

export const generateOtpForPurchase = createAsyncThunk<
  string,
  void,
  { state: AppState; rejectValue: DisneyActionErrorResponse }
>("appsDisney/generateOtpForPurchase", async (request, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsUser: { purchaseValidateToken, purchaseSelection },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let httpStatus = "";
  const apiEndpoint = settings.apiEndpoint.purchaseOtp;
  const { accountId } = purchaseSelection.smartcard as SelectedSmartcard;
  try {
    const body: PurchaseOtpRequest = {
      purchaseValidateToken,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" && (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<PurchaseOtpResponse>;
    if (isErrorResponse(data.response)) {
      const errorCode = getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error = { errorCode, errorDisplayCode, contactForm: false };
      if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
      throw error;
    }
    return data.response.purchaseValidateOtpToken;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus,
        account_id: accountId,
      })
    );
    if (contactForm) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountId,
          screen_name: bcpContactForm,
          reason: errorCode,
          api_endpoint: apiEndpoint,
        })
      );
      thunkApi.dispatch(replace(`/${disneyContactPath}`));
    }
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
  }
});

export const purchase = createAsyncThunk<string, string, { state: AppState; rejectValue: DisneyActionErrorResponse }>(
  "appsDisney/purchase",
  async (otp, thunkApi) => {
    const {
      ulm: { ulmToken },
      appsOtp: { purchaseValidateOtpToken },
      appsContact,
      appsLanguage: { fallbackErrorDisplayCode },
      appsUser: {
        purchaseSelection: { smartcard },
      },
    } = thunkApi.getState();
    let httpStatus = "";
    const apiEndpoint = settings.apiEndpoint.purchase;
    const { accountId } = smartcard as SelectedSmartcard;
    try {
      const body: PurchaseRequest = {
        purchaseValidateOtpToken,
        otp,
      };
      process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" &&
        otp === process.env.REACT_APP_DISNEY_MOCK_OTP &&
        (body.mock = true);

      const response = await delayedFetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: ulmToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      httpStatus = response.status.toString();
      const data = (await response.json()) as APIResponse<PurchaseResponse>;
      if (isErrorResponse(data.response)) {
        const isTokenError = isTokenValidationError(data.response, validationErrorTokenValue.purchase);
        const errorCode = isTokenError ? errorCodes.validationErrorToken : getErrorCode(data.response);
        const errorDisplayCode = getErrorDisplayCode(data.response);
        const error = { errorCode, errorDisplayCode, contactForm: false };
        if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
        throw error;
      }
      return data.response.activationValidateToken;
    } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
      const errorCode: string = _errorCode ?? errorCodes.genericError;
      const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
      thunkApi.dispatch(
        trackDisneyError({
          api_endpoint: apiEndpoint,
          error_code: errorCode,
          error_status: httpStatus,
          account_id: accountId,
        })
      );
      if (contactForm) {
        thunkApi.dispatch(
          trackDisneyTraffic({
            account_id: accountId,
            screen_name: bcpContactForm,
            reason: errorCode,
            api_endpoint: apiEndpoint,
          })
        );
        thunkApi.dispatch(replace(`/${disneyContactPath}`));
      }
      return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
    }
  }
);

export const activatePurchase = createAsyncThunk<
  string,
  string,
  { state: AppState; rejectValue: DisneyActionErrorResponse }
>("appsDisney/activatePurchase", async (otp, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsOtp: { activationValidateToken },
    appsUser: {
      purchaseSelection: { hasConsentedToUpdatePhone, addOnId, smartcard },
      disneyAddOnsConfig,
    },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let httpStatus = "";
  const apiEndpoint = settings.apiEndpoint.activate;
  const { accountId } = smartcard as SelectedSmartcard;
  try {
    const packId = disneyAddOnsConfig.addOns.find(({ id }) => id === addOnId)?.packId ?? "";
    const body: ActivateRequest = {
      activationValidateToken,
      otp,
      packId,
      isAlacarte: true,
      updatePrimaryPhone: hasConsentedToUpdatePhone,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" &&
      otp === process.env.REACT_APP_DISNEY_MOCK_OTP &&
      (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<ActivateResponse>;
    if (isErrorResponse(data.response)) {
      const isTokenError = isTokenValidationError(data.response, validationErrorTokenValue.activate);
      const errorCode = isTokenError ? errorCodes.validationErrorToken : getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error = { errorCode, errorDisplayCode, contactForm: false };
      if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
      throw error;
    }
    return data.response.status;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: errorCode,
        error_status: httpStatus,
        account_id: accountId,
      })
    );
    if (contactForm) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountId,
          screen_name: bcpContactForm,
          reason: errorCode,
          api_endpoint: apiEndpoint,
        })
      );
      thunkApi.dispatch(replace(`/${disneyContactPath}`));
    }
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
  }
});

export const validateActivationForDuplicatePurchase = createAsyncThunk<
  string,
  void,
  { state: AppState; rejectValue: DisneyActionErrorResponse }
>("appsDisney/validateActivateForDuplicatePurchase", async (request, thunkApi) => {
  const {
    ulm: { ulmToken },
    appsUser: {
      eligibilityToken,
      purchaseSelection: { mobilePrefix, mobileSuffix, smartcard },
    },
    appsContact,
    appsLanguage: { fallbackErrorDisplayCode },
  } = thunkApi.getState();
  let httpStatus = "";
  const apiEndpoint = settings.apiEndpoint.validateActivate;
  const { accountId, smartcardNumber } = smartcard as SelectedSmartcard;
  const phoneNumber = mobilePrefix + mobileSuffix;
  try {
    const body: ValidateActivateRequest = {
      eligibilityToken,
      accountId,
      smc: smartcardNumber,
      phoneNumber,
    };
    process.env.REACT_APP_DISNEY_USE_MOCK_OTP === "true" && (body.mock = true);

    const response = await delayedFetch(apiEndpoint, {
      method: "POST",
      headers: {
        Authorization: ulmToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    httpStatus = response.status.toString();
    const data = (await response.json()) as APIResponse<ValidateActivateResponse>;
    if (isErrorResponse(data.response)) {
      const errorCode = getErrorCode(data.response);
      const errorDisplayCode = getErrorDisplayCode(data.response);
      const error = { errorCode, errorDisplayCode, contactForm: false };
      if (isContactPageError(appsContact, errorCode)) error.contactForm = true;
      throw error;
    }
    return data.response.activationValidateToken;
  } catch ({ errorCode: _errorCode, errorDisplayCode: _errorDisplayCode, contactForm }) {
    const errorCode: string = _errorCode ?? errorCodes.genericError;
    const errorDisplayCode: string = _errorDisplayCode ?? fallbackErrorDisplayCode;
    thunkApi.dispatch(
      trackDisneyError({
        api_endpoint: apiEndpoint,
        error_code: _errorCode,
        error_status: httpStatus,
        account_id: accountId,
      })
    );
    if (contactForm) {
      thunkApi.dispatch(
        trackDisneyTraffic({
          account_id: accountId,
          screen_name: bcpContactForm,
          reason: errorCode,
          api_endpoint: apiEndpoint,
        })
      );
      thunkApi.dispatch(replace(`/${disneyContactPath}`));
    }
    return thunkApi.rejectWithValue({ errorCode, errorDisplayCode, contactForm, apiEndpoint });
  }
});

export const resync = createAsyncThunk<void, void, { state: AppState; rejectValue: void }>(
  "appsDisney/resync",
  async (request, thunkApi) => {
    const {
      ulm: { ulmToken, puid, userContactId },
      appsUser: { entitlementToken, accountIds },
    } = thunkApi.getState();

    // Check if resync API should be called
    const resyncCookie = getCookie(cookie.resync);
    const contactIdCookie = getCookie(cookie.contactId);
    if (resyncCookie && contactIdCookie === userContactId) return;

    let httpStatus = "";
    const endpoint = settings.apiEndpoint.resync;
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ulmToken,
        },
        body: JSON.stringify(entitlementToken ? { entitlementToken } : {}),
      });
      httpStatus = response.status.toString();
      const data = await response.json();
      if (isErrorResponse(data.response)) {
        const errorCode = getErrorCode(data.response);
        const error = { errorCode };
        throw error;
      }
      return;
    } catch ({ errorCode: _errorCode }) {
      const errorCode = _errorCode ?? errorCodes.genericError;
      thunkApi.dispatch(
        trackDisneyError({
          api_endpoint: endpoint,
          error_code: errorCode,
          error_status: httpStatus,
          account_id: accountIds ?? puid,
        })
      );
    }
  }
);

/* Utility functions */

export function delayedFetch(input: RequestInfo, init?: RequestInit, minimumDelay?: number): Promise<Response> {
  return pMinDelay(fetch(input, init), minimumDelay ?? 800);
}

function getMobileNumber(selection: ActivationSelection): string {
  const { mobilePrefix, mobileSuffix } = selection;
  return mobilePrefix + mobileSuffix;
}

function isSmartcardWithValidStatus(smartcard: SmartcardEligibility): boolean {
  const { activationStatus } = smartcard;
  return isAvailable(activationStatus) || isActive(activationStatus) || isInProgress(activationStatus);
}

function isContactPageError(appsContactState: AppsContactState, errorCode: string | null): boolean {
  return !!errorCode && appsContactState.config.errorCodes.includes(errorCode);
}

export function isDisneyContactRedirection(
  action: unknown
): action is PayloadAction<DisneyActionErrorResponse, string> {
  const { type, payload } = action as PayloadAction<DisneyActionErrorResponse, string>;
  return type.endsWith("/rejected") && payload && payload.contactForm;
}
